/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    blockquote: "blockquote",
    strong: "strong",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A dynamic descriptor is text that describes a transaction on a customer’s statement. A dynamic descriptor helps to reduce the risk of a chargeback because it helps the customer to identify the transaction.\nA dynamic descriptor can contain information about the transaction, for example:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Order number"), "\n", React.createElement(_components.li, null, "Merchant's phone number"), "\n", React.createElement(_components.li, null, "Merchant's Doing Business As (DBA) name"), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " A dynamic descriptor is also known as a billing descriptor."), "\n"), "\n", React.createElement(_components.h2, null, "How we use dynamic descriptors"), "\n", React.createElement(_components.p, null, "We use hard dynamic descriptors to display the descriptor on the customer’s statement after the processor has settled the transaction."), "\n", React.createElement(_components.p, null, "To create a dynamic descriptor, use our custom fields feature to create a custom field and assign it as a dynamic descriptor. For more information about how to create custom fields and dynamic descriptors, go to our ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/guides/integrate/add-custom-fields"
  }, "Custom Fields"), " guide."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
